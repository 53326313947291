<div class="footer bg-primary-50">
  <div class="d-flex">
    <ng-container *ngFor="let item of items">
      <div class="col d-flex flex-column justify-content-center align-items-center">
        <a
          matRipple
          class="pills"
          routerLinkActive="active"
          [routerLink]="item.link"
          #rla="routerLinkActive">
          <mat-icon [fontSet]="rla.isActive ? '' : 'material-icons-outlined'">{{item.icon}}</mat-icon>
        </a>
      </div>
    </ng-container>
  </div>
</div>
