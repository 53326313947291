<mat-nav-list>
  <a
    mat-list-item
    (click)="onDeleteClicked()">
    <mat-icon
      mat-list-icon
      class="text-danger">
      delete
    </mat-icon>
    <span
      mat-line
      class="text-danger">
      Supprimer la dépense
    </span>
  </a>
</mat-nav-list>
