import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExpenseType } from '@eros/expense-type.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HeadersTool } from '../_tools/headers';

@Injectable()
export class ExpenseTypeService {

  private route = '/expenses-types';

  constructor(
    private http: HttpClient,
  ) {}

  public getAll(): Observable<ExpenseType[]> {
    return this.http.get<ExpenseType[]>(`${environment.apiUrl}${this.route}`, {
      headers: HeadersTool.getHeaders(),
    });
  }

}
