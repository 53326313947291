import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

export interface SnackBarParam {
  message: string;
  action?: string;
  config?: MatSnackBarConfig;
}

@Injectable()
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  public show(param: SnackBarParam): void {
    this.snackBar.open(
      param.message,
      param.action || 'Fermer',
      {
        duration: 3000,
      },
    );
  }

}
