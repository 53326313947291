import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExpenseWording } from '@eros/expense-wording.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HeadersTool } from '../_tools/headers';

@Injectable()
export class ExpenseWordingService {

  private route = '/expenses-wording';

  constructor(
    private http: HttpClient,
  ) {}

  public getAll(): Observable<ExpenseWording[]> {
    return this.http.get<ExpenseWording[]>(`${environment.apiUrl}${this.route}`, {
      headers: HeadersTool.getHeaders(),
    });
  }

}
