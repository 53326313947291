import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ExpenseAccount } from '@eros/expense-account.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Observable, switchMap, takeUntil } from 'rxjs';
import { BsExpenseAccountComponent } from '../_bottom-sheets/bs-expense-account/bs-expense-account.component';
import { UnsubscribeOnDestroy } from '../_classes/unsuscribe-on-destroy';
import { ExpenseAccountService } from '../_services/expense-account.service';
import { SnackBarService } from '../_services/utilities/snack-bar.service';

@Component({
  selector: 'app-expenses-account',
  templateUrl: './expenses-account.component.html',
  styleUrls: ['./expenses-account.component.scss'],
})
export class ExpensesAccountComponent extends UnsubscribeOnDestroy implements OnInit {

  public searchValue: string | null = null;
  public expensesAccounts: ExpenseAccount[] = [];
  public intinialExpensesAccounts: ExpenseAccount[] = [];

  constructor(
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private expenseAccountService: ExpenseAccountService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setExpensesAccounts();
  }

  onClearSearchClicked(): void {
    this.searchValue = null;
    this.onSearchValueChanged();
  }

  onSearchValueChanged(): void {
    if (this.searchValue == null) {
      this.expensesAccounts = this.intinialExpensesAccounts;
    } else {
      this.expensesAccounts = this.intinialExpensesAccounts.filter((e: ExpenseAccount) => this.variableIncludesSearch(e.number));
    }
  }

  onExpenseAccountClicked(expAcc: ExpenseAccount): void {
    this.router.navigateByUrl(`notes-frais/${expAcc.id}/depenses`);
  }

  onAddExpenseAccountClicked(): void {
    this.bottomSheet.open(BsExpenseAccountComponent, { panelClass: 'bs-fullscreen' })
      .afterDismissed()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((obs: Observable<any>) => {
        this.spinner.show();
        this.setExoensesAccountAfterBs(obs);
      });
  }

  private setExpensesAccounts(): void {
    this.spinner.show();
    this.expenseAccountService.getAll()
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => this.spinner.hide()),
      )
      .subscribe({
        next: (expenses: ExpenseAccount[]) => {
          this.intinialExpensesAccounts = expenses;
          this.expensesAccounts = expenses;
        },
        error: (error) => this.snackBarService.show({ message: error }),
      });
  }

  private setExoensesAccountAfterBs(obs: Observable<any>): void {
    if (obs) {
      obs
        .pipe(
          takeUntil(this.isDestroyed$),
          switchMap(() => this.expenseAccountService.getAll()),
        )
        .subscribe(
          {
            next: (expenses: ExpenseAccount[]) => {
              this.intinialExpensesAccounts = expenses;
              this.expensesAccounts = expenses;
            },
            error: (error) => this.snackBarService.show({ message: error }),
            complete: () => this.spinner.hide(),
          },
        );
    } else {
      this.spinner.hide();
    }
  }

  private variableIncludesSearch(variable: string | number | null): boolean {
    if (variable != null && this.searchValue != null) {
      return variable.toString().trim().toLowerCase().includes(this.searchValue.trim().toLowerCase());
    }
    return false;
  }

}
