<span mat-dialog-title>
  <strong>Êtes-vous sûr(e) ?</strong>
</span>
<div mat-dialog-content>
  Une fois envoyée, une note de frais ne peut plus être éditée.
</div>
<div class="d-flex flex-column w-100 mt-3">
  <button
    mat-flat-button
    color="primary"
    (click)="onSendClicked()">
    Envoyer
  </button>
  <button
    class="my-1"
    mat-stroked-button
    (click)="onCancelClicked()">
    Annuler
  </button>
</div>
