/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthenticationService {

  public token: string | null;
  private headers: HttpHeaders;
  private readonly apiUrl = environment.apiUrl;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Access-Control-Allow-Headers', 'Origin, Authorization, Content-Type, Accept');

    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
    this.token = currentUser && currentUser.token;
  }

  login(form: FormGroup): Observable<any> {
    return this.http.post(`${this.apiUrl}/authenticate`, form.value, { headers: this.headers })
      .pipe(
        map((response: any) => {
          this.token = response['accessToken'];
          const email = response['email'];
          if (this.token) {
            localStorage.setItem(
              'user',
              JSON.stringify({ email: email, token: this.token }),
            );
            this.loggedIn.next(true);
            this.router.navigate(['/accueil']);
          }
          return response;
        }),
      );
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem('user');
  }

  getToken(): string | null {
    return this.token;
  }

  isTokenExpired(token?: string | null): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date!.valueOf() > new Date().valueOf());
  }

  getUserInfos(token?: string): any {
    const decoded = this.getDecodedToken(token);

    if (decoded.lastname === undefined) return null;
    return {
      id: decoded.id,
      email: decoded.email,
      lastname: decoded.lastname,
      firstname: decoded.firstname,
      initials: decoded.initials,
    };
  }

  setTokenAndRedirect(token: string, email: string): void {
    this.token = token;
    if (this.token) {
      localStorage.setItem('user', JSON.stringify({ email: email, token: this.token }));
      this.loggedIn.next(true);
      this.router.navigate(['/accueil']);
    }
  }

  private getTokenExpirationDate(token: string): Date | null {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  private getDecodedToken(token?: string | null) {
    if (!token) token = this.getToken();
    if (!token) return false;
    return jwt_decode(token);
  }

}
