import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectItem } from '@eros/select.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HeadersTool } from '../_tools/headers';

@Injectable()
export class ContactService {

  private route = '/contacts';

  constructor(
    private http: HttpClient,
  ) {}

  public getAll(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiUrl}${this.route}/select`, {
      headers: HeadersTool.getHeaders(),
    });
  }

}
