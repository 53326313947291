import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss'],
})
export class HeaderToolbarComponent implements OnInit {

  @Input() title: string | undefined;
  @Input() showLocationBackButton: boolean = false;
  @Input() showMoreButton: boolean = false;
  @Output() more = new EventEmitter();

  constructor(
    private location: Location,
  ) { }

  ngOnInit(): void {
  }

  onLocationBack(): void {
    this.location.back();
  }

  onMoreClicked(): void {
    this.more.emit();
  }

}
