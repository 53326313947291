import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/_classes/unsuscribe-on-destroy';
import { DialogDeleteConfirmComponent } from 'src/app/_dialogs/dialog-delete-confirm/dialog-delete-confirm.component';
import { SnackBarService } from 'src/app/_services/utilities/snack-bar.service';

@Component({
  selector: 'app-bs-expense-actions',
  templateUrl: './bs-expense-actions.component.html',
  styleUrls: ['./bs-expense-actions.component.scss'],
})
export class BsExpenseActionsComponent extends UnsubscribeOnDestroy implements OnInit {

  constructor(
    private bottomSheet: MatBottomSheetRef<BsExpenseActionsComponent, boolean>,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  onDeleteClicked(): void {
    this.dialog.open(DialogDeleteConfirmComponent, {
      autoFocus: false,
      data: 'La dépense sera définitivement supprimée.',
    })
      .afterClosed()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: (value: boolean) => {
          if (value) {
            this.bottomSheet.dismiss(true);
          }
        },
        error: (error) => this.snackBarService.show({ message: error }),
      });
  }

}
