import { AuthenticationService } from 'src/app/_services/utilities/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
  ) {
    this.route.queryParams
      .subscribe((params: any) => {
        if (params.email !== '' && params.token !== '') {
          this.authService.setTokenAndRedirect(params.token, params.email);
        }
      });
  }

  ngOnInit(): void {
  }

}
