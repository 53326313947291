<mat-toolbar
  class="header justify-content-center"
  color="primary">
  <ng-container *ngIf="showLocationBackButton">
    <div class="position-absolute start-0">
      <button
        mat-icon-button
        (click)="onLocationBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </ng-container>
  <span>{{title || ''}}</span>
  <ng-container *ngIf="showMoreButton">
    <div class="position-absolute end-0">
      <button
        mat-icon-button
        (click)="onMoreClicked()">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </ng-container>
</mat-toolbar>
