import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-without-sidebar',
  templateUrl: './app-without-sidebar.component.html',
  styleUrls: ['./app-without-sidebar.component.scss'],
})
export class AppWithoutSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
