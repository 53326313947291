import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ExpenseAccount } from '@eros/expense-account.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ExpenseAccountService } from 'src/app/_services/expense-account.service';

@Component({
  selector: 'app-bs-expense-account',
  templateUrl: './bs-expense-account.component.html',
  styleUrls: ['./bs-expense-account.component.scss'],
})
export class BsExpenseAccountComponent implements OnInit {

  public form: FormGroup;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public expenseAccount: ExpenseAccount,
    private bottomSheet: MatBottomSheetRef<BsExpenseAccountComponent, Observable<any>>,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private expenseAccountService: ExpenseAccountService,
  ) { }

  ngOnInit(): void {
    if (this.expenseAccount) {
      this.form = this.formBuilder.group({
        number: [this.expenseAccount.number || '', Validators.required],
      });
    } else {
      this.expenseAccountService.generateNumber().subscribe(
        (number) => {
            this.form = this.formBuilder.group({
        number: [number || '', Validators.required],
        });
      })
    }
  }

  getTitleText(): string {
    return this.expenseAccount != null ? 'Édition' : 'Nouvelle';
  }

  getButtonText(): string {
    return this.expenseAccount != null ? 'Modifier' : 'Ajouter';
  }

  onCloseClicked(): void {
    this.bottomSheet.dismiss();
  }

  onSaveClicked(): void {
    if (this.form.valid) {
      if (this.expenseAccount) {
        this.bottomSheet.dismiss(this.expenseAccountService.update(this.expenseAccount.id, this.form.value));
      } else {
        this.bottomSheet.dismiss(this.expenseAccountService.create(this.form.value));
      }
    }
  }

}
