import { E } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Event } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/utilities/authentication.service';
import { SnackBarService } from 'src/app/_services/utilities/snack-bar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public isLoading: boolean = false;
  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  onSubmit(): void {
    // stop here if form is invalid
    if (this.form.invalid) {
      // eslint-disable-next-line no-useless-return
      return;
    }
    this.isLoading = true;
    this.authService.login(this.form)
      .subscribe({
        next: () => { this.isLoading = false; },
        error: (error) => {
          this.isLoading = false;
          this.snackBarService.show({ message: 'Connexion impossible' });
        },
      });
  }

  loginWithMicrosoft(e: any): void {
    this.isLoading = true;
    const originUrl = environment.apiOriginUrl;
    window.location.href = `${originUrl}login-with-microsoft/1`;
    e.preventDefault();
  }

}
