import { Routes } from '@angular/router';

// Layouts
import { AuthGuard } from './_guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { AppWithoutSidebarComponent } from './_layouts/app-without-sidebar/app-without-sidebar.component';
import { LoginComponent } from './_auth/login/login.component';
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { ExpensesAccountComponent } from './expenses-account/expenses-account.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { ExpenseComponent } from './expense/expense.component';
import { RedirectComponent } from './_auth/redirect/redirect.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AppWithSidebarComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'accueil',
        component: HomeComponent,
      },
      {
        path: 'notes-frais',
        component: ExpensesAccountComponent,
      },
      {
        path: 'notes-frais/:id/depenses',
        component: ExpensesComponent,
      },
      {
        path: 'notes-frais/:expAccountId/depenses/creation',
        component: ExpenseComponent,
      },
      {
        path: 'notes-frais/:expAccountId/depenses/:expId/modification',
        component: ExpenseComponent,
      },
    ],
  },
  {
    path: '',
    component: AppWithoutSidebarComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
    ],
  },
  { path: 'redirect', component: RedirectComponent },
];
