<app-header-toolbar
  [title]="expenseAccount?.number"
  [showLocationBackButton]="true"
  [showMoreButton]="!isLoading && !expenseAccount?.isLocked"
  (more)="onMoreClicked()">
</app-header-toolbar>
<div class="content">
  <mat-form-field
    class="w-100"
    appearance="outline">
    <mat-label>Rechercher...</mat-label>
    <input
      matInput
      type="text"
      [(ngModel)]="searchValue"
      (ngModelChange)="onSearchValueChanged()">
    <button
      *ngIf="searchValue"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="onClearSearchClicked()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <ng-container *ngFor="let exp of expenses">
    <mat-card
      class="mb-3"
      matRipple
      (click)="onExpenseClicked(exp)">
      <div class="d-flex justify-content-start align-items-end">
        <mat-icon>
          {{exp.expensesAmounts[0].expenseType.icon}}
        </mat-icon>
        <span class="fw-bold ms-2">
          {{exp.expensesAmounts[0].expenseType.name}}
        </span>
      </div>
      <div>
        <span class="fw-bold opacity-75">
          {{ exp.expenseWording ? exp.expenseWording.name : exp.otherWording }}
        </span>
      </div>
      <div class="d-flex flex-column justify-content-start">
        <span class="opacity-75">{{exp.dateFormatted}}</span>
        <div class="d-flex align-items-end">
          <div class="me-auto">
            <ng-container *ngIf="!exp.path || exp.path.length === 0">
              <div class="d-flex align-items-end">
                <mat-icon
                  fontSet="material-icons-outlined"
                  class="text-danger opacity-75">
                  report
                </mat-icon>
                <span class="text-danger opacity-75">Photo manquante</span>
              </div>
            </ng-container>
          </div>
          <div class="ms-auto">
            <span class="opacity-75">
              {{exp.amountTotalAllTaxes.toFixed(2)}}€ TTC
            </span>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="expenses.length === 0 && searchValue != null">
    Aucun élément à afficher pour la recherche &#171;{{searchValue}}&#187;.
  </ng-container>
  <div
    class="btn-bottom"
    *ngIf="!isLoading && !expenseAccount?.isLocked">
    <button
      mat-fab
      color="accent"
      (click)="onNewClicked()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
