import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@eros/user.model';
import { AuthenticationService } from '../_services/utilities/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  public userInfos: User;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.userInfos = this.authService.getUserInfos();
  }

  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

}
