// Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Modules Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// Modules
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// Interceptors
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthGuard } from './_guards/auth.guard';
import { TokenInterceptor } from './_interceptors/token-interceptor';

// Services
import { AuthenticationService } from './_services/utilities/authentication.service';
import { SnackBarService } from './_services/utilities/snack-bar.service';
import { ExpenseAccountService } from './_services/expense-account.service';
import { ExpenseService } from './_services/expense.service';
import { ContactService } from './_services/contact.service';
import { UserService } from './_services/user.service';
import { ExpenseTypeService } from './_services/expense-type.service';
import { ExpenseWordingService } from './_services/expense-wording.service';

// Components
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { AppWithoutSidebarComponent } from './_layouts/app-without-sidebar/app-without-sidebar.component';
import { LoginComponent } from './_auth/login/login.component';
import { HomeComponent } from './home/home.component';
import { ExpensesAccountComponent } from './expenses-account/expenses-account.component';
import { DialogExpenseAccountComponent } from './_dialogs/dialog-expense-account/dialog-expense-account.component';
import { NavigationBottomComponent } from './_components/navigation-bottom/navigation-bottom.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { BsExpenseAccountComponent } from './_bottom-sheets/bs-expense-account/bs-expense-account.component';
import { BsExpenseAccountActionsComponent } from './_bottom-sheets/bs-expense-account-actions/bs-expense-account-actions.component';
import { HeaderToolbarComponent } from './_components/header-toolbar/header-toolbar.component';
import { DialogDeleteConfirmComponent } from './_dialogs/dialog-delete-confirm/dialog-delete-confirm.component';
import { DialogSendConfirmComponent } from './_dialogs/dialog-send-confirm/dialog-send-confirm.component';
import { ExpenseComponent } from './expense/expense.component';
import { BsExpenseActionsComponent } from './_bottom-sheets/bs-expense-actions/bs-expense-actions.component';
import { DialogImgComponent } from './_dialogs/dialog-img/dialog-img.component';
import { environment } from '../environments/environment';
import { RedirectComponent } from './_auth/redirect/redirect.component';

@NgModule({
  declarations: [
    AppComponent,
    AppWithSidebarComponent,
    AppWithoutSidebarComponent,
    LoginComponent,
    HomeComponent,
    ExpensesAccountComponent,
    ExpensesComponent,
    ExpenseComponent,
    // Components
    NavigationBottomComponent,
    HeaderToolbarComponent,
    RedirectComponent,
    // Dialogs
    DialogExpenseAccountComponent,
    DialogDeleteConfirmComponent,
    DialogSendConfirmComponent,
    DialogImgComponent,
    // Bottom Sheets
    BsExpenseAccountComponent,
    BsExpenseAccountActionsComponent,
    BsExpenseActionsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // Modules Material
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatRippleModule,
    MatBottomSheetModule,
    MatListModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSelectModule,
    MatChipsModule,
    MatDividerModule,
    MatProgressBarModule,
    // Modules
    NgxSpinnerModule,
    NgxMatSelectSearchModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR',
    },
    AuthenticationService,
    SnackBarService,
    ExpenseAccountService,
    ExpenseService,
    ContactService,
    UserService,
    ExpenseTypeService,
    ExpenseWordingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
