<mat-toolbar
  class="justify-content-center"
  color="primary">
  <div class="position-absolute start-0">
    <button
      mat-icon-button
      (click)="onCloseClicked()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <span>{{getTitleText()}}</span>
</mat-toolbar>
<form
  [formGroup]="form"
  *ngIf="form">
  <div class="d-flex flex-column align-items-center w-100">
    <mat-form-field appearance="outline">
      <mat-label>Numéro</mat-label>
      <input
        type="text"
        matInput
        placeholder="Numéro"
        formControlName="number">
      <mat-error *ngIf="form.controls['number'].hasError('required')">Le numéro est obligatoire</mat-error>
    </mat-form-field>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      (click)="onSaveClicked()">
      {{getButtonText()}}
    </button>
    <button
      mat-stroked-button
      type="button"
      (click)="onCloseClicked()">
      Annuler
    </button>
  </div>
</form>
