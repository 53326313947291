<app-header-toolbar title="Notes de frais"></app-header-toolbar>
<div class="content">
  <mat-form-field
    class="w-100"
    appearance="outline">
    <mat-label>Rechercher...</mat-label>
    <input
      matInput
      type="text"
      [(ngModel)]="searchValue"
      (ngModelChange)="onSearchValueChanged()">
    <button
      *ngIf="searchValue"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="onClearSearchClicked()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <ng-container *ngFor="let expAcc of expensesAccounts">
    <mat-card
      class="mb-3"
      matRipple
      (click)="onExpenseAccountClicked(expAcc)">
      <div class="d-flex align-items-start">
        <div class="me-auto">
          <div class="d-flex flex-column">
            <span class="fw-bold">{{expAcc.number}}</span>
            <span class="opacity-75">-</span>
            <span class="opacity-75">
              {{expAcc.expenses.length}} dépense(s) au total
            </span>
          </div>
        </div>
        <div class="ms-auto">
          <div
            class="chips"
            [style.background-color]="expAcc.expenseAccountStatus?.color">
            <mat-icon fontSet="material-icons-outlined">{{expAcc.expenseAccountStatus?.icon}}</mat-icon>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="expensesAccounts.length === 0 && searchValue != null">
    Aucun élément à afficher pour la recherche &#171;{{searchValue}}&#187;.
  </ng-container>
  <div class="btn-bottom">
    <button
      mat-fab
      color="accent"
      (click)="onAddExpenseAccountClicked()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
