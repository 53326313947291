import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Expense } from '@eros/expense.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Observable, of, takeUntil, zip } from 'rxjs';
import { Location } from '@angular/common';
import { COMMA, ENTER, F } from '@angular/cdk/keycodes';
import { ExpenseAmount } from '@eros/expense-amount.model';
import { ExpenseType } from '@eros/expense-type.model';
import { ExpenseWording } from '@eros/expense-wording.model';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@eros/user.model';
import { SelectItem } from '@eros/select.model';
import { Contact } from '@eros/contact.model';
import { ExpenseParcipant } from '@eros/expense-participant.model';
import { BsExpenseActionsComponent } from '../_bottom-sheets/bs-expense-actions/bs-expense-actions.component';
import { UnsubscribeOnDestroy } from '../_classes/unsuscribe-on-destroy';
import { ExpenseService } from '../_services/expense.service';
import { SnackBarService } from '../_services/utilities/snack-bar.service';
import { UserService } from '../_services/user.service';
import { ContactService } from '../_services/contact.service';
import { ExpenseWordingService } from '../_services/expense-wording.service';
import { DialogImgComponent } from '../_dialogs/dialog-img/dialog-img.component';
import { ExpenseTypeService } from '../_services/expense-type.service';

type ParticipantType = 'NONE' | 'CONTACT' | 'PROSPECT';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpenseComponent extends UnsubscribeOnDestroy implements OnInit {

  public toolbarTitle: string = '';
  public isLoading: boolean = false;
  public maxAmountTemplate: number = 5;
  public expense: Expense | null = null;
  public expensesWording: ExpenseWording[] = [];
  public initialExpensesWording: ExpenseWording[] = [];
  public expensesTypes: ExpenseType[] = [];
  public initialExpensesTypes: ExpenseType[] = [];
  public initialUsers: SelectItem[] = [];
  public users: SelectItem[] = [];
  public initialContacts: SelectItem[] = [];
  public contacts: SelectItem[] = [];
  public totalAmount: number = 0;
  public fileToUpload: File | null = null;
  public selectedParticipant: ParticipantType = 'NONE';
  public form: FormGroup;
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;
  public readonly maxParticipant: number = 10;
  public readonly maxAmount: number = 5;
  private expenseAccountId: number = -1;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private contactService: ContactService,
    private expenseService: ExpenseService,
    private expenseWordingService: ExpenseWordingService,
    private expenseTypeService: ExpenseTypeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((params: ParamMap) => {
        this.expenseAccountId = Number(params.get('expAccountId'));
        const expId = Number(params.get('expId'));
        this.getVariables(expId);
      });
  }

  onMoreClicked(): void {
    this.bottomSheet.open(BsExpenseActionsComponent).afterDismissed()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((val: boolean) => {
        if (val) {
          this.spinner.show();
          this.expenseService.delete(this.expense!.id)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe({
              next: (success: string) => {
                this.location.back();
              },
              error: (error) => {
                this.snackBarService.show({ message: error });
                this.spinner.hide();
              },
            });
        }
      });
  }

  onFileSelected(evt: Event): void {
    const targ = (evt.target as HTMLInputElement);
    if (targ != null) {
      this.fileToUpload = targ.files[0];
    }
  }

  onShowFile(): void {
    this.dialog.open(DialogImgComponent, {
      autoFocus: false,
      panelClass: 'dialog-no-padding',
      backdropClass: 'dialog-backdrop-black',
      data: this.expense?.thumbnail,
    });
  }

  getFormControls(field: string): any[] {
    return (this.form.get(field) as FormArray).controls;
  }

  onRemoveClicked(field: string, i: number): void {
    (this.form.get(field) as FormArray).removeAt(i);
    this.onTotalChanged();
  }

  onAddAmountClicked(): void {
    const formArray = (this.form.get('expensesAmounts') as FormArray);
    if (formArray.length < this.maxAmount) {
      formArray.push(this.getExpensesAmountsForm(null));
    }
  }

  onAddParticipantClicked(): void {
    const formArray = (this.form.get('expensesParticipants') as FormArray);
    if (formArray.length < this.maxParticipant) {
      formArray.push(this.getExpensesParticipantsForm(null));
    }
  }

  onTotalChanged(): void {
    const formatted = this.form.value as Expense;
    this.totalAmount = 0;
    formatted.expensesAmounts.forEach((e: ExpenseAmount) => {
      this.totalAmount += e.amountTotalAllTaxes;
    });
  }

  onSaveClicked(): void {
    if (this.form.valid) {
      this.spinner.show();
      let observable: Observable<any>;
      this.form.value.date = this.form.value.date ? new Date(this.form.value.date).toDateString() : null;
      if (this.expense) {
        observable = this.expenseService.update(this.expense.id, this.form.value);
      } else {
        observable = this.expenseService.create(this.form.value);
      }
      observable
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: (returnedExpense: any) => {
            if (this.fileToUpload) {
              this.expenseService.import(returnedExpense.data.id, this.fileToUpload)
                .pipe(takeUntil(this.isDestroyed$))
                .subscribe({
                  next: () => {
                    this.location.back();
                    this.spinner.hide();
                  },
                  error: (error) => {
                    this.snackBarService.show({ message: error });
                    this.spinner.hide();
                  },
                });
            } else {
              this.location.back();
              this.spinner.hide();
            }
          },
          error: (error) => {
            this.snackBarService.show({ message: error });
            this.spinner.hide();
          },
        });
    } else {
      this.snackBarService.show({ message: 'Formulaire invalide' });
    }
  }

  private getVariables(id: number): void {
    this.isLoading = true;
    this.spinner.show();
    zip(
      this.expenseWordingService.getAll(),
      this.userService.getAll(),
      this.contactService.getAll(),
      this.expenseTypeService.getAll(),
      (this.isValidNumber(id)) ? this.expenseService.get(id) : of(this.getEmptyExpense()),
    )
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => {
          this.isLoading = false;
          this.spinner.hide();
        }),
      )
      .subscribe({
        next: ([wording, users, contacts, expensesTypes, expense]) => {
          this.expensesWording = wording;
          this.initialExpensesWording = wording;
          this.initialUsers = users;
          this.users = users;
          this.initialContacts = contacts;
          this.contacts = contacts;
          this.initialExpensesTypes = expensesTypes;
          if (this.isValidNumber(id)) {
            this.toolbarTitle = expense.dateFormatted;
            this.expense = expense;
            this.selectedParticipant = this.setSelectedParticipant();
          } else {
            this.toolbarTitle = 'Nouvelle';
          }
          this.initForm();
          if (this.expense != null) {
            this.onTotalChanged();
            this.setExpensesTypes(expense.expenseWording?.id);
          }
        },
        error: (error) => this.snackBarService.show({ message: error }),
      });
  }

  private initForm(): void {
    let expenseWordingId = null;
    if (this.expense) {
      if (this.expense.expenseWording) {
        expenseWordingId = this.expense.expenseWording.id;
      } else {
        expenseWordingId = -1;
      }
    }
    this.form = this.formBuilder.group({
      expenseAccountId: [this.expenseAccountId, Validators.required],
      date: [this.expense?.date || null, Validators.required],
      expenseWordingSearch: '',
      expenseWordingId: [expenseWordingId || null, Validators.required],
      otherWording: [this.expense?.otherWording],
      participantType: [this.setSelectedParticipant(), Validators.required],
      users: this.expense?.users.map((e: User) => e.id) || [],
      userSearch: '',
      contacts: this.expense?.contacts.map((e: Contact) => e.id) || [],
      contactSearch: '',
      expensesParticipants: new FormArray(this.getExpensesParticipants()),
      expensesAmounts: new FormArray(this.getExpensesAmounts()),
    });
    if (expenseWordingId === -1) {
      this.otherWording.setValidators(Validators.required);
      this.otherWording.updateValueAndValidity();
    }
    this.setValueChangesSubscriptions();
  }

  onChangeExpenseWording(): void {
    const value = this.form.value.expenseWordingId;
    console.log(value);
    if (value === -1) {
      this.otherWording.setValidators(Validators.required);
      this.otherWording.updateValueAndValidity();
    } else {
      this.otherWording.setValidators(null);
      this.otherWording.updateValueAndValidity();
    }
  }

  get otherWording(): FormControl {
    return this.form.get('otherWording') as FormControl;
  }

  private setValueChangesSubscriptions(): void {
    this.form.get('expenseWordingId')?.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((id) => {
        this.setExpensesTypes(Number(id));
      });
    this.form.get('participantType')?.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((type: ParticipantType) => {
        this.selectedParticipant = type;
        this.clearParticipantSelection(type);
      });
    this.form.get('userSearch')?.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((search: string) => {
        this.users = this.initialUsers.filter((user: SelectItem) => user.label.toLowerCase().indexOf(search.trim().toLowerCase()) > -1);
      });
    this.form.get('contactSearch')?.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((search: string) => {
        this.contacts = this.initialContacts.filter((user: SelectItem) => user.label.toLowerCase().indexOf(search.trim().toLowerCase()) > -1);
      });
    this.form.get('expenseWordingSearch')?.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((search: string) => {
        this.expensesWording = this.initialExpensesWording.filter((expenseWording: ExpenseWording) => expenseWording.name.toLowerCase().indexOf(search.trim().toLowerCase()) > -1);
      });
  }

  getExpenseWordingIdValue(): number {
    return this.form.get('expenseWordingId')?.value;
  }

  private getExpensesAmounts(): any[] {
    const expensesAmounts: any[] = [];
    if (this.expense) {
      this.expense.expensesAmounts.forEach((expAm: ExpenseAmount) => {
        expensesAmounts.push(this.getExpensesAmountsForm(expAm));
      });
    } else {
      expensesAmounts.push(this.getExpensesAmountsForm(null));
    }
    return expensesAmounts;
  }

  private getExpensesAmountsForm(expAm: ExpenseAmount | null): FormGroup {
    return this.formBuilder.group({
      expenseTypeId: [expAm?.expenseType.id || null, Validators.required],
      amountVatFive: expAm?.amountVatFive || 0,
      amountVatTen: expAm?.amountVatTen || 0,
      amountVatTwenty: expAm?.amountVatTwenty || 0,
      amountTotalAllTaxes: [expAm?.amountTotalAllTaxes || 0, Validators.required],
    });
  }

  private getExpensesParticipants(): any[] {
    const expensesParticipants: any[] = [];
    if (this.expense) {
      this.expense.expensesParticipants.forEach((expPart: ExpenseParcipant) => {
        expensesParticipants.push(this.getExpensesParticipantsForm(expPart));
      });
    }
    return expensesParticipants;
  }

  private getExpensesParticipantsForm(expPart: ExpenseParcipant | null): FormGroup {
    return this.formBuilder.group({
      fullName: [expPart?.fullName || '', Validators.required],
      society: expPart?.society || '',
    });
  }

  private setExpensesTypes(expWordingId: number): void {
    const expWording = this.expensesWording.find((e: ExpenseWording) => e.id === expWordingId);
    if (expWording != null) {
      this.expensesTypes = expWording.expensesTypes;
    } else {
      this.expensesTypes = this.initialExpensesTypes;
    }
  }

  private isValidNumber(id: number): boolean {
    return !Number.isNaN(id) && id > 0;
  }

  private getEmptyExpense(): Expense {
    return {
      id: 0,
      expenseAccountId: 0,
      expenseWording: {
        id: 0,
        expensesTypes: [],
        name: '',
        thirdPartyAccount: '',
      },
      otherWording: '',
      date: '',
      dateFormatted: '',
      path: '',
      thumbnail: '',
      amountTotalWithoutTaxes: 0,
      amountTotalAllTaxes: 0,
      expensesAmounts: [],
      contacts: [],
      users: [],
      expensesParticipants: [],
      isLocked: false,
    };
  }

  private setSelectedParticipant(): ParticipantType {
    if (this.expense?.users.length > 0 && this.expense?.contacts.length > 0) {
      this.selectedParticipant = 'CONTACT';
      return 'CONTACT';
    }
    if (this.expense?.expensesParticipants.length > 0) {
      this.selectedParticipant = 'PROSPECT';
      return 'PROSPECT';
    }
    this.selectedParticipant = 'NONE';
    return 'NONE';
  }

  private clearParticipantSelection(type: ParticipantType): void {
    this.form.patchValue({
      users: [],
      userSearch: '',
      contacts: [],
      contactSearch: '',
    });
    const formArray = (this.form.get('expensesParticipants') as FormArray);
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
    if (type === 'PROSPECT') {
      this.onAddParticipantClicked();
    }
  }

}
