<div>
  <span mat-dialog-title>{{getTitleText()}} note de frais</span>
</div>
<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Numéro</mat-label>
    <input
      matInput
      [(ngModel)]="number">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button
    mat-flat-button
    mat-dialog-close>
    Retour
  </button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button
    mat-flat-button
    color="accent"
    [disabled]="!canSave()"
    (click)="onSaveClicked()">
    {{getButtonText()}}
  </button>
</mat-dialog-actions>
