import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ExpenseAccountService } from 'src/app/_services/expense-account.service';

@Component({
  selector: 'app-dialog-send-confirm',
  templateUrl: './dialog-send-confirm.component.html',
  styleUrls: ['./dialog-send-confirm.component.scss'],
})
export class DialogSendConfirmComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogSendConfirmComponent, boolean>,
  ) { }

  ngOnInit(): void {
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }

  onSendClicked(): void {
    this.dialogRef.close(true);
  }

}
