<app-header-toolbar title="Connexion"></app-header-toolbar>
<mat-progress-bar [mode]="isLoading ? 'query' : 'determinate'"></mat-progress-bar>
<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>Adresse e-mail</mat-label>
    <input
      type="email"
      matInput
      placeholder="Adresse e-mail"
      formControlName="email">
    <mat-error *ngIf="form.controls['email'].hasError('email') && !form.controls['email'].hasError('required')">L'adresse e-mail n'est pas valide</mat-error>
    <mat-error *ngIf="form.controls['email'].hasError('required')">L'email est obligatoire</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Mot de passe</mat-label>
    <input
      type="password"
      matInput
      placeholder="Mot de passe"
      formControlName="password">
    <mat-error *ngIf="form.controls['password'].hasError('minlength') && !form.controls['password'].hasError('required')">
      Le mot de passe doit faire plus de 6 caractères
    </mat-error>
    <mat-error *ngIf="form.controls['password'].hasError('required')">Le mot de passe est obligatoire</mat-error>
  </mat-form-field>
  <button
    mat-flat-button
    color="primary"
    type="submit"
    [disabled]="isLoading"
    (click)="onSubmit()">
    <span *ngIf="!isLoading">Se connecter</span>
    <span *ngIf="isLoading">Connexion en cours...</span>
  </button>
  <button
    mat-flat-button
    color="basic"
    [disabled]="isLoading"
    (click)="loginWithMicrosoft($event)">
    <span *ngIf="!isLoading">Se connecter avec Microsoft</span>
    <span *ngIf="isLoading">Redirection en cours...</span>
  </button>
</form>
