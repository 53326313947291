import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExpenseAccount } from '@eros/expense-account.model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HeadersTool } from '../_tools/headers';

@Injectable()
export class ExpenseAccountService {

  private route = '/expenses-accounts';

  constructor(
    private http: HttpClient,
  ) {}

  public getAll(): Observable<ExpenseAccount[]> {
    return this.http.get<ExpenseAccount[]>(`${environment.apiUrl}${this.route}/list`, {
      headers: HeadersTool.getHeaders(),
    });
  }

  public get(id: number): Observable<ExpenseAccount> {
    return this.http.get<ExpenseAccount>(`${environment.apiUrl}${this.route}/${id}`, {
      headers: HeadersTool.getHeaders(),
    });
  }

  public create(expense: ExpenseAccount): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${this.route}`,
      {
        number: expense.number,
      },
      {
        headers: HeadersTool.getHeaders(),
      },
    );
  }

  public update(id: number, expense: ExpenseAccount): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${this.route}/${id}`,
      {
        number: expense.number,
      },
      {
        headers: HeadersTool.getHeaders(),
      },
    );
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}${this.route}/${id}`,
      {
        headers: HeadersTool.getHeaders(),
      },
    );
  }

  public send(id:number): Observable<any> {
    return this.http.get<ExpenseAccount>(`${environment.apiUrl}${this.route}/${id}/send`, {
      headers: HeadersTool.getHeaders(),
    });
  }

  public generateNumber(): Observable<any> {
  return this.http.get<ExpenseAccount>(`${environment.apiUrl}${this.route}/generate-number`, {
      headers: HeadersTool.getHeaders(),
    });
  }
  
}
