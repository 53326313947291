import { Component, Input, OnInit } from '@angular/core';

export interface NavigationBottomItem {
  link: string;
  icon: string;
}

@Component({
  selector: 'app-navigation-bottom',
  templateUrl: './navigation-bottom.component.html',
  styleUrls: ['./navigation-bottom.component.scss'],
})
export class NavigationBottomComponent implements OnInit {

  @Input() items: NavigationBottomItem[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
