<app-header-toolbar title="MyEros"></app-header-toolbar>
<div class="content">
  <mat-card class="mt-5">
    <div class="d-flex justify-content-center mt-n5">
      <div class="circle bg-primary-100">
        <span class="initials">{{userInfos.initials}}</span>
      </div>
    </div>
    <div class="d-flex mt-3">
      <span>Bienvenue, {{userInfos.firstname}}.</span>
    </div>
  </mat-card>
  <div class="mt-5">
    <button
      mat-stroked-button
      (click)="logout()">
      Se déconnecter
    </button>
  </div>
</div>
