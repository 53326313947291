import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ExpenseAccount } from '@eros/expense-account.model';
import { Expense } from '@eros/expense.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Observable, switchMap, takeUntil, zip } from 'rxjs';
import { BsExpenseAccountComponent } from '../_bottom-sheets/bs-expense-account/bs-expense-account.component';
import { BsExpenseAction, BsExpenseAccountActionsComponent } from '../_bottom-sheets/bs-expense-account-actions/bs-expense-account-actions.component';
import { UnsubscribeOnDestroy } from '../_classes/unsuscribe-on-destroy';
import { ExpenseAccountService } from '../_services/expense-account.service';
import { ExpenseService } from '../_services/expense.service';
import { SnackBarService } from '../_services/utilities/snack-bar.service';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss'],
})
export class ExpensesComponent extends UnsubscribeOnDestroy implements OnInit {

  public searchValue: string | null = null;
  public expenseAccount: ExpenseAccount;
  public expenses: Expense[] = [];
  public intinialExpenses: Expense[] = [];
  public isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private expenseAccountService: ExpenseAccountService,
    private expenseService: ExpenseService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.spinner.show();
    this.route.paramMap
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((params: ParamMap) => {
        this.setVariables(Number(params.get('id')));
      });
  }

  onClearSearchClicked(): void {
    this.searchValue = null;
    this.onSearchValueChanged();
  }

  onSearchValueChanged(): void {
    if (this.searchValue == null) {
      this.expenses = this.intinialExpenses;
    } else {
      this.expenses = this.intinialExpenses.filter((e: Expense) => this.variableIncludesSearch(e.path));
    }
  }

  onExpenseClicked(exp: Expense): void {
    this.router.navigateByUrl(`notes-frais/${this.expenseAccount.id}/depenses/${exp.id}/modification`);
  }

  onNewClicked(): void {
    this.router.navigateByUrl(`notes-frais/${this.expenseAccount.id}/depenses/creation`);
  }

  onMoreClicked(): void {
    this.bottomSheet.open(BsExpenseAccountActionsComponent).afterDismissed()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((type: BsExpenseAction) => {
        if (type === 'EDIT') {
          this.bottomSheet.open(BsExpenseAccountComponent, {
            panelClass: 'bs-fullscreen',
            data: this.expenseAccount,
          }).afterDismissed()
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe((obs: Observable<any>) => {
              this.spinner.show();
              this.setExpenseAccount(obs);
            });
        } else if (type === 'SEND') {
          this.spinner.show();
           this.expenseAccountService.send(this.expenseAccount!.id)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe({
              next: (success: string) => {
                this.router.navigate(['/notes-frais']);
              },
              error: (error) => this.snackBarService.show({ message: error }),
              complete: () => this.spinner.hide(),
            });
        } else if (type === 'DELETE') {
          this.spinner.show();
          this.expenseAccountService.delete(this.expenseAccount!.id)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe({
              next: (success: string) => {
                this.router.navigate(['/notes-frais']);
              },
              error: (error) => this.snackBarService.show({ message: error }),
              complete: () => this.spinner.hide(),
            });
        }
      });
  }

  private setVariables(id: number): void {
    this.spinner.show();
    zip(
      this.expenseAccountService.get(id),
      this.expenseService.getByExpenseAccount(id),
    )
      .pipe(
        takeUntil(this.isDestroyed$),
        finalize(() => {
          this.isLoading = false;
          this.spinner.hide();
        }),
      )
      .subscribe({
        next: ([expenseAccount, expenses]) => {
          this.expenseAccount = expenseAccount;
          this.intinialExpenses = expenses;
          this.expenses = expenses;
        },
        error: (error) => this.snackBarService.show({ message: error }),
      });
  }

  private setExpenseAccount(obs: Observable<any>): void {
    if (obs) {
      obs
        .pipe(
          takeUntil(this.isDestroyed$),
          switchMap(() => this.expenseAccountService.get(this.expenseAccount.id)),
        )
        .subscribe({
          next: (expenseAccount: ExpenseAccount) => {
            this.expenseAccount = expenseAccount;
          },
          error: (error) => this.snackBarService.show({ message: error }),
          complete: () => this.spinner.hide(),
        });
    } else {
      this.spinner.hide();
    }
  }

  private variableIncludesSearch(variable: string | number | null): boolean {
    if (variable != null && this.searchValue != null) {
      return variable.toString().trim().toLowerCase().includes(this.searchValue.trim().toLowerCase());
    }
    return false;
  }

}
