import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-delete-confirm',
  templateUrl: './dialog-delete-confirm.component.html',
  styleUrls: ['./dialog-delete-confirm.component.scss'],
})
export class DialogDeleteConfirmComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogContent: string,
    private dialogRef: MatDialogRef<DialogDeleteConfirmComponent, boolean>,
  ) { }

  ngOnInit(): void {
  }

  onCancelClicked(): void {
    this.dialogRef.close(false);
  }

  onDeleteClicked(): void {
    this.dialogRef.close(true);
  }

}
