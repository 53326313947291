<app-header-toolbar
  [title]="toolbarTitle"
  [showLocationBackButton]="true"
  [showMoreButton]="!isLoading && expense && !expense?.isLocked"
  (more)="onMoreClicked()">
</app-header-toolbar>
<form
  class="expense-form"
  [class.disabled-form]="expense?.isLocked"
  [formGroup]="form"
  *ngIf="form">
  <mat-card>
    <mat-card-content>
      <mat-form-field appearance="outline">
        <mat-label>Date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="date">
        <mat-datepicker-toggle
          matSuffix
          [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker
          touchUi
          #picker>
        </mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Libellé</mat-label>
        <mat-select
          matNativeControl
          formControlName="expenseWordingId"
          (selectionChange)="onChangeExpenseWording()">
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Rechercher..."
              formControlName="expenseWordingSearch">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="-1">AUTRE</mat-option>
          <mat-option
            [value]="wording.id"
            *ngFor="let wording of expensesWording">
            {{wording.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        *ngIf="getExpenseWordingIdValue() == -1">
        <mat-label>Autre libellé</mat-label>
        <input
          matInput
          type="text"
          formControlName="otherWording">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Type participant</mat-label>
        <select
          matNativeControl
          formControlName="participantType">
          <option value="NONE">Aucun</option>
          <option value="CONTACT">Collègue et/ou client</option>
          <option value="PROSPECT">Propect et/ou autre</option>
        </select>
      </mat-form-field>
      <ng-container *ngIf="selectedParticipant === 'CONTACT'">
        <mat-form-field appearance="outline">
          <mat-label>Collègue(s)</mat-label>
          <mat-select
            multiple
            formControlName="users">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Rechercher..."
                formControlName="userSearch">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let user of users"
              [value]="user.value">
              {{user.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Client(s)</mat-label>
          <mat-select
            multiple
            formControlName="contacts">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Rechercher..."
                formControlName="contactSearch">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let contact of contacts"
              [value]="contact.value">
              {{contact.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="selectedParticipant === 'PROSPECT'">
        <button
          class="my-2"
          type="button"
          mat-stroked-button
          (click)="onAddParticipantClicked()">
          Ajouter un participant
        </button>
        <ng-container
          formArrayName="expensesParticipants"
          *ngFor="let house of getFormControls('expensesParticipants'); let i = index;">
          <hr>
          <div [formGroupName]="i">
            <div class="d-flex align-items-start">
              <div class="me-auto">
                <span class="opacity-75">Participant {{i+1}}</span>
              </div>
              <div class="ms-auto">
                <div
                  class="icon-btn"
                  matRipple
                  (click)="onRemoveClicked('expensesParticipants', i)"
                  *ngIf="!expense?.isLocked">
                  <mat-icon class="text-danger">close</mat-icon>
                </div>
              </div>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Prénom et nom</mat-label>
              <input
                matInput
                type="text"
                formControlName="fullName">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Société</mat-label>
              <input
                matInput
                type="text"
                formControlName="society">
            </mat-form-field>
          </div>
        </ng-container>
      </ng-container>
    </mat-card-content>
  </mat-card>
  <ng-container
    formArrayName="expensesAmounts"
    *ngFor="let house of getFormControls('expensesAmounts'); let i = index;">
    <mat-card
      class="mt-2"
      [formGroupName]="i">
      <div class="d-flex align-items-start">
        <div class="me-auto">
          <span class="opacity-75">Montant {{i+1}}</span>
        </div>
        <div class="ms-auto">
          <div
            class="icon-btn"
            matRipple
            (click)="onRemoveClicked('expensesAmounts', i)"
            *ngIf="!expense?.isLocked">
            <mat-icon class="text-danger">close</mat-icon>
          </div>
        </div>
      </div>
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select
            matNativeControl
            formControlName="expenseTypeId">
            <mat-option
              [value]="type.id"
              *ngFor="let type of expensesTypes">
              {{type.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>TVA 5.5</mat-label>
          <input
            matInput
            type="number"
            formControlName="amountVatFive">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>TVA 10</mat-label>
          <input
            matInput
            type="number"
            formControlName="amountVatTen">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>TVA 20</mat-label>
          <input
            matInput
            type="number"
            formControlName="amountVatTwenty">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Montant TTC</mat-label>
          <input
            matInput
            type="number"
            formControlName="amountTotalAllTaxes"
            (change)="onTotalChanged()">
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <div class="mt-2">
    <div class="d-flex align-items-start">
      <div class="me-auto">
        <h3>Montant TTC TOTAL :</h3>
      </div>
      <div class="ms-auto">
        <h2>{{totalAmount.toFixed(2)}}€</h2>
      </div>
    </div>
    <button
      class="my-2"
      type="button"
      mat-flat-button
      color="accent"
      (click)="onAddAmountClicked()"
      *ngIf="!expense?.isLocked">
      Ajouter un montant
    </button>
    <button
      type="button"
      mat-flat-button
      color="accent"
      (click)="fileInput.click()"
      *ngIf="!fileToUpload && !expense?.path">
      Ajouter une photo
    </button>
    <button
      type="button"
      mat-stroked-button
      disabled
      *ngIf="fileToUpload">
      <mat-icon>check</mat-icon>
      Photo ajoutée avec succès
    </button>
    <button
      type="button"
      mat-stroked-button
      (click)="onShowFile()"
      *ngIf="expense && expense.path && !fileToUpload">
      Consulter la photo existante
    </button>
    <button
      class="mt-2"
      type="button"
      mat-flat-button
      color="accent"
      (click)="fileInput.click()"
      *ngIf="!expense?.isLocked && expense && expense.path && !fileToUpload">
      Remplacer la photo existante
    </button>
    <input
      hidden
      #fileInput
      type="file"
      accept="image/*;capture=camera"
      (change)="onFileSelected($event)">
  </div>
  <div
    class="btn-bottom"
    *ngIf="!isLoading && !expense?.isLocked">
    <button
      mat-fab
      color="primary"
      (click)="onSaveClicked()">
      <mat-icon>save</mat-icon>
    </button>
  </div>
</form>
