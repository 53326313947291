import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Expense } from '@eros/expense.model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HeadersTool } from '../_tools/headers';

@Injectable()
export class ExpenseService {

  private route = '/expenses';

  constructor(
    private http: HttpClient,
  ) {}

  public getByExpenseAccount(id: number): Observable<Expense[]> {
    return this.http.post<Expense[]>(
      `${environment.apiUrl}${this.route}/list`,
      {
        expenseAccountId: id,
      },
      {
        headers: HeadersTool.getHeaders(),
      },
    );
  }

  public get(id: number): Observable<Expense> {
    return this.http.get<Expense>(`${environment.apiUrl}${this.route}/${id}`, {
      headers: HeadersTool.getHeaders(),
    });
  }

  public create(expense: Expense): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}${this.route}`,
      expense,
      {
        headers: HeadersTool.getHeaders(),
      },
    );
  }

  public update(id: number, expense: Expense): Observable<Expense> {
    return this.http.put<Expense>(
      `${environment.apiUrl}${this.route}/${id}`,
      expense,
      {
        headers: HeadersTool.getHeaders(),
      },
    );
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}${this.route}/${id}`,
      {
        headers: HeadersTool.getHeaders(),
      },
    );
  }

  public import(expenseId: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('expenseId', expenseId.toString());
    formData.append('file', file);

    return this.http.post(`${environment.apiUrl}${this.route}/import-file`, formData);
  }

}
