<mat-nav-list>
  <a
    mat-list-item
    (click)="onExpenseAccountEditClicked()">
    <mat-icon mat-list-icon>edit</mat-icon>
    <span mat-line>Éditer la note de frais</span>
  </a>
  <a
    mat-list-item
    (click)="onExpenseAccountSendClicked()">
    <mat-icon mat-list-icon>send</mat-icon>
    <span mat-line>Envoyer la note de frais</span>
  </a>
  <a
    mat-list-item
    (click)="onExpenseAccountDeleteClicked()">
    <mat-icon
      mat-list-icon
      class="text-danger">
      delete
    </mat-icon>
    <span
      mat-line
      class="text-danger">
      Supprimer la note de frais
    </span>
  </a>
</mat-nav-list>
