import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExpenseAccount } from '@eros/expense-account.model';
import { ExpenseAccountService } from 'src/app/_services/expense-account.service';

@Component({
  selector: 'app-dialog-expense-account',
  templateUrl: './dialog-expense-account.component.html',
  styleUrls: ['./dialog-expense-account.component.scss'],
})
export class DialogExpenseAccountComponent implements OnInit {

  public number: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public expenseAccount: ExpenseAccount,
    private dialogRef: MatDialogRef<DialogExpenseAccountComponent>,
    private expenseAccountService: ExpenseAccountService,
  ) { }

  ngOnInit(): void {
    if (this.expenseAccount != null) {
      this.number = this.expenseAccount.number;
    }
  }

  getTitleText(): string {
    return this.expenseAccount != null ? 'Édition' : 'Création';
  }

  getButtonText(): string {
    return this.expenseAccount != null ? 'Édition' : 'Ajouter';
  }

  canSave(): boolean {
    return this.number != null && this.number.trim().length > 0;
  }

  onSaveClicked(): void {
    this.dialogRef.close();
  }

}
