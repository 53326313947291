import { Component, OnInit } from '@angular/core';
import { NavigationBottomItem } from 'src/app/_components/navigation-bottom/navigation-bottom.component';

@Component({
  selector: 'app-app-with-sidebar',
  templateUrl: './app-with-sidebar.component.html',
  styleUrls: ['./app-with-sidebar.component.scss'],
})
export class AppWithSidebarComponent implements OnInit {

  public navigationItems: NavigationBottomItem[] = [
    {
      link: '/accueil',
      icon: 'home',
    },
    {
      link: '/notes-frais',
      icon: 'receipt',
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
