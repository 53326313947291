import { HttpHeaders } from '@angular/common/http';

export class HeadersTool {

  public static getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

}
