import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { UnsubscribeOnDestroy } from 'src/app/_classes/unsuscribe-on-destroy';
import { DialogDeleteConfirmComponent } from 'src/app/_dialogs/dialog-delete-confirm/dialog-delete-confirm.component';
import { DialogSendConfirmComponent } from 'src/app/_dialogs/dialog-send-confirm/dialog-send-confirm.component';
import { SnackBarService } from 'src/app/_services/utilities/snack-bar.service';

export type BsExpenseAction = 'EDIT' | 'SEND' | 'DELETE';

@Component({
  selector: 'app-bs-expense-account-actions',
  templateUrl: './bs-expense-account-actions.component.html',
  styleUrls: ['./bs-expense-account-actions.component.scss'],
})
export class BsExpenseAccountActionsComponent extends UnsubscribeOnDestroy implements OnInit {

  constructor(
    private bottomSheet: MatBottomSheetRef<BsExpenseAccountActionsComponent, BsExpenseAction>,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  onExpenseAccountEditClicked(): void {
    this.bottomSheet.dismiss('EDIT');
  }

  onExpenseAccountSendClicked(): void {
    this.dialog.open(DialogSendConfirmComponent, { autoFocus: false })
      .afterClosed()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: (value: boolean) => {
          if (value) {
            this.bottomSheet.dismiss('SEND');
          }
        },
        error: (error) => this.snackBarService.show({ message: error }),
      });
  }

  onExpenseAccountDeleteClicked(): void {
    this.dialog.open(DialogDeleteConfirmComponent, {
      autoFocus: false,
      data: 'La suppression d\'une note de frais entraîne la suppression des dépenses associées.',
    })
      .afterClosed()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: (value: boolean) => {
          if (value) {
            this.bottomSheet.dismiss('DELETE');
          }
        },
        error: (error) => this.snackBarService.show({ message: error }),
      });
  }

}
